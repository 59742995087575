/* eslint-disable no-unused-vars */
export type Country = {
  region: string
  code: string
  name: string
  phoneCode: string
  currencyCode: CurrencyCode
  serverLocation: ServerLocation
  isVatEligible: boolean
}

export enum CurrencyCode {
  EUR = 'EUR',
  USD = 'USD',
  GBP = 'GBP',
  JPY = 'JPY',
}

export enum CurrencySymbols {
  EUR = '€',
  GBP = '£',
  USD = '$',
  JPY = '¥',
}

export enum ServerLocation {
  EUROPE = 'EUROPE',
  SOUTHEAST_ASIA = 'SOUTHEAST_ASIA',
  NORTH_AMERICA = 'NORTH_AMERICA',
}

export enum CountryCode {
  AL = 'AL',
  DZ = 'DZ',
  AD = 'AD',
  AO = 'AO',
  AG = 'AG',
  AR = 'AR',
  AU = 'AU',
  AT = 'AT',
  BS = 'BS',
  BD = 'BD',
  BB = 'BB',
  BY = 'BY',
  BH = 'BH',
  BE = 'BE',
  BZ = 'BZ',
  BJ = 'BJ',
  BT = 'BT',
  BO = 'BO',
  BA = 'BA',
  BW = 'BW',
  BR = 'BR',
  BN = 'BN',
  BG = 'BG',
  BF = 'BF',
  BI = 'BI',
  CV = 'CV',
  KH = 'KH',
  CM = 'CM',
  CA = 'CA',
  CF = 'CF',
  TD = 'TD',
  CL = 'CL',
  CN = 'CN',
  CO = 'CO',
  KM = 'KM',
  CG = 'CG',
  CR = 'CR',
  HR = 'HR',
  CU = 'CU',
  CY = 'CY',
  CZ = 'CZ',
  CI = 'CI',
  KP = 'KP',
  CK = 'CK',
  CD = 'CD',
  DK = 'DK',
  DJ = 'DJ',
  DO = 'DO',
  EC = 'EC',
  EG = 'EG',
  SV = 'SV',
  GQ = 'GQ',
  ER = 'ER',
  EE = 'EE',
  SZ = 'SZ',
  ET = 'ET',
  FJ = 'FJ',
  FI = 'FI',
  FR = 'FR',
  GA = 'GA',
  GM = 'GM',
  GE = 'GE',
  DE = 'DE',
  GH = 'GH',
  GR = 'GR',
  GD = 'GD',
  GT = 'GT',
  GN = 'GN',
  GW = 'GW',
  HT = 'HT',
  VA = 'VA',
  HN = 'HN',
  HK = 'HK',
  HU = 'HU',
  IS = 'IS',
  IN = 'IN',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IT = 'IT',
  JM = 'JM',
  JP = 'JP',
  KZ = 'KZ',
  KE = 'KE',
  XT = 'XT',
  KI = 'KI',
  LA = 'LA',
  LV = 'LV',
  LB = 'LB',
  LS = 'LS',
  LR = 'LR',
  LY = 'LY',
  LI = 'LI',
  LT = 'LT',
  LU = 'LU',
  MG = 'MG',
  MW = 'MW',
  MY = 'MY',
  MV = 'MV',
  ML = 'ML',
  MT = 'MT',
  MH = 'MH',
  MR = 'MR',
  MU = 'MU',
  MX = 'MX',
  FM = 'FM',
  MC = 'MC',
  MN = 'MN',
  ME = 'ME',
  MA = 'MA',
  MZ = 'MZ',
  MM = 'MM',
  NA = 'NA',
  NR = 'NR',
  NP = 'NP',
  NL = 'NL',
  NZ = 'NZ',
  NI = 'NI',
  NC = 'NC',
  NE = 'NE',
  NG = 'NG',
  NO = 'NO',
  PK = 'PK',
  PW = 'PW',
  PA = 'PA',
  PG = 'PG',
  PY = 'PY',
  PE = 'PE',
  PH = 'PH',
  PL = 'PL',
  PR = 'PR',
  PT = 'PT',
  KR = 'KR',
  MD = 'MD',
  RO = 'RO',
  RU = 'RU',
  RW = 'RW',
  KN = 'KN',
  LC = 'LC',
  VC = 'VC',
  WS = 'WS',
  SM = 'SM',
  ST = 'ST',
  SN = 'SN',
  RS = 'RS',
  SC = 'SC',
  SL = 'SL',
  SG = 'SG',
  SK = 'SK',
  SI = 'SI',
  SB = 'SB',
  SO = 'SO',
  ZA = 'ZA',
  ES = 'ES',
  LK = 'LK',
  SD = 'SD',
  SR = 'SR',
  SE = 'SE',
  CH = 'CH',
  TW = 'TW',
  TH = 'TH',
  MK = 'MK',
  TG = 'TG',
  TO = 'TO',
  TT = 'TT',
  TN = 'TN',
  TR = 'TR',
  TV = 'TV',
  UG = 'UG',
  UA = 'UA',
  AE = 'AE',
  GB = 'GB',
  TZ = 'TZ',
  US = 'US',
  UY = 'UY',
  VU = 'VU',
  VE = 'VE',
  VN = 'VN',
  ZM = 'ZM',
  ZW = 'ZW',
}
